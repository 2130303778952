import { Button, Paper, Typography } from "@mui/material";
import { Home } from "@mui/icons-material";
import Page from "material-ui-shell/lib/containers/Page/Page";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useMenu } from "material-ui-shell/lib/providers/Menu";
const PageNotFound = () => {
  const intl = useIntl();
  const { toggleThis } = useMenu();
  useEffect(() => {
    toggleThis("isAuthMenuOpen", false);
    toggleThis("isMenuOpen", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const css = `.MuiIconButton-root {
    display:none}`;
  return (
    <>
      <style>{css}</style>
      <Page pageTitle={intl.formatMessage({ id: "page_not_found" })}>
        <Paper
          sx={{
            backgroundColor: (t) => t.palette.background.default,
            margin: 0,
            height: `calc(100vh - 64px)`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: `100%`,
            }}
          >
            <Typography variant="h4">404</Typography>
            <Typography variant="subtitle1">
              {intl.formatMessage({ id: "page_not_found" })}
            </Typography>
            <Button
              color="secondary"
              aria-label="home"
              href="/"
              style={{ marginTop: 20 }}
            >
              <Home />
            </Button>
          </div>
        </Paper>
      </Page>
    </>
  );
};

export default PageNotFound;
